/* eslint-disable camelcase */
import {
  GET_SOME_CONVERSATIONS,
  ACTIVATE_CONVERSATION,
  ADD_NEW_CONVERSATION,
  GET_CONVERSATION_MESSAGES,
  MARK_CONVERSATIONS_AS_DONE,
  ADD_UPCOMING_MESSAGE_TO_CONVERSATION,
  TAKE_OVER,
  ADD_NOTE_TO_PERSON_FROM_CONVERSATION,
  MARK_LAST_MESSAGE_AS_OLD,
  GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS,
  DEACTIVATE_CONVERSATION,
  TAKE_OVER_FAILED,
  UN_TAKE_OVER,
  MARK_AS_DONE,
  MARK_AS_OPEN,
  FETCH_MORE_MESSAGES,
  CLEAR_CONVERSATIONS_LIST,
  GET_LIVE_MODE_VALUE,
  UPDATE_LIVE_MODE_VALUE,
  UPDATE_USER_STATUS,
  UPDATE_CONVERSATION_SENTIMENT,
  MARK_AS_DONE_FROM_LIST,
  UPDATE_USER_INFO,
} from 'store/actions/types/conversations';
import { produce, setAutoFreeze } from 'immer';
import { mapArrayToObject } from 'helpers/stateManagement';

const initialState = {
  facebook: {
    users: null,
    liveChat: null,
    meta: null,
  },
  whatsapp: {
    users: null,
    liveChat: null,
    meta: null,
  },
  instagram: {
    users: null,
    liveChat: null,
    meta: null,
  },
  web: {
    users: null,
    liveChat: null,
    meta: null,
  },
  members: null,
  meta: {
    error: null,
    action: null,
  },
  savedReplies: [],
  isLiveMode: true,
};

export default (state = initialState, { type, payload, error }) =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (type) {
      case GET_SOME_CONVERSATIONS: {
        const { conversations, provider, page, meta } = payload;
        // if (draft[provider]) draft[provider].users = conversations;
        // un comment  upper condition and comment below when pagination is not  enabled
        if (draft[provider])
          if (page === 1) {
            // if first page  we doesn't need to add to old conversations instead of that we replace
            draft[provider].users = conversations;
            draft[provider].meta = meta;
          } else {
            draft[provider].users = {
              ...state[provider].users,
              ...conversations,
            };
            draft[provider].meta = meta;
          }
        draft.meta = {
          action: GET_SOME_CONVERSATIONS,
          error: null,
        };
        break;
      }

      case ADD_NEW_CONVERSATION: {
        const { conversation, provider, live_mode, filter_match } = payload;
        const id = Object.keys(conversation)[0];

        // if the user already exists, update the new number of unseen_messages_count
        if (draft[provider].users[id]) {
          draft[provider].users[id].unseen_messages_count =
            conversation[id].unseen_messages_count;
        }
        if (!draft[provider].users[id] && filter_match) {
          draft[provider].users = {
            ...conversation,
            ...draft[provider].users,
          }
        }
        // if live_mode is true then push the upcoming conversation to the top and then other conversations
        if (live_mode && filter_match) {
          draft[provider].users = {
            ...conversation,
            ...draft[provider].users,
          };
        }

        const isConversationSameWithLiveChat =
          draft[provider].liveChat?.attributes?.id === Number(id);

        if (isConversationSameWithLiveChat) {
          draft[provider].users[id].unseen_messages_count = 0;
        }

        draft.meta = { action: ADD_NEW_CONVERSATION, error: null };
        break;
      }

      case UPDATE_USER_STATUS: {
        const { conversation, provider, filter_match, conversationId, live_mode } = payload;


        if (!live_mode) {
          //if live mode is false then then don't update the user status
          return;
        } 


        if (filter_match && live_mode) {
          draft[provider].users = {
            ...state[provider].users,
            ...conversation,
          }
        } else {
          const allConversations = Object.values(state[provider]?.users)
          if (draft[provider]) {
            const updatedConversations = allConversations.filter(conversation => conversation.id !== parseInt(conversationId));
            draft[provider].liveChat = null;
            draft[provider].users = mapArrayToObject(updatedConversations, true, true)
          } else if (draft[provider.name]) {
            draft[provider.name].liveChat = null;
          }
        }

        draft.meta = { action: UPDATE_USER_STATUS, error: null };
        break;
      }
      case UPDATE_USER_INFO: {
        const { conversation, provider, filter_match, conversationId, live_mode } = payload;

        if (!live_mode) {
          return;
        }
        if (filter_match && live_mode) {
          draft[provider].users = {
            ...conversation,
            ...draft[provider].users,
          }
        } else {
          const allConversations = Object.values(state[provider]?.users)

          if (draft[provider]) {
            const updatedConversations = allConversations.filter(conversation => conversation.id !== parseInt(conversationId));
            draft[provider].liveChat = null;
            draft[provider].users = mapArrayToObject(updatedConversations, true, true);
          } else if (draft[provider.name]) {
            draft[provider.name].liveChat = null;
          }
        }

        draft.meta = { action: UPDATE_USER_STATUS, error: null };
        break;
      }

      case MARK_CONVERSATIONS_AS_DONE: {
        const { conversationsList, provider } = payload;

        // users
        conversationsList.forEach((id) => {
          draft[provider].users[id].statue = 'done';
          draft[provider].users[id].attributes.statue = 'done';
        });
        // live chat
        draft[provider].liveChat.attributes.status = 'done';
        draft.meta = {
          action: MARK_CONVERSATIONS_AS_DONE,
          error: null,
        };
        break;
      }

      case MARK_AS_DONE_FROM_LIST: {
        const { conversationId, provider, conversation } = payload;

        // users
        draft[provider].users[conversationId].statue = 'done';
        draft[provider].users[conversationId].attributes.statue =
          'done';
        // live chat
        if (draft[provider].liveChat !== null) {
          draft[provider].liveChat.attributes.statue = 'done';
        }
        if (draft[provider].users[conversationId]) {
          delete draft[provider].users[conversationId];
        }
        draft.meta = { action: MARK_AS_DONE, error: null };
        break;
      }
      case MARK_AS_DONE: {
        const { conversationId, provider } = payload;

        // users
        draft[provider].users[conversationId].statue = 'done';
        draft[provider].users[conversationId].attributes.statue =
          'done';
        // live chat
        draft[provider].liveChat.attributes.statue = 'done';
        draft.meta = { action: MARK_AS_DONE, error: null };
        break;
      }
      case MARK_AS_OPEN: {
        const { conversationId, provider } = payload;
        // users
        draft[provider].users[conversationId].statue = 'open';
        draft[provider].users[conversationId].attributes.statue =
          'open';
        // live chat
        draft[provider].liveChat.attributes.statue = 'open';
        draft.meta = { action: MARK_AS_OPEN, error: null };
        break;
      }
      case FETCH_MORE_MESSAGES: {

        const { conversation, provider } = payload;
        // live chat

        const mergedMessages = { ...draft[provider]?.liveChat?.attributes?.messages?.messages }
        for (const key in conversation?.messages) {
          if (mergedMessages[key]) {
            mergedMessages[key] = [...mergedMessages[key]].concat([...conversation?.messages[key]])
          } else {
            mergedMessages[key] = conversation?.messages[key];
          }
        }
        draft[provider].liveChat.attributes.messages.messages = mergedMessages
        draft[provider].liveChat.attributes.messages.meta = conversation?.meta

        draft.meta = { action: FETCH_MORE_MESSAGES, error: null };
        break;
      }

      case ACTIVATE_CONVERSATION: {
        const { conversation, provider } = payload;

        // live chat
        draft[provider].liveChat = conversation;
        // const conversationId = conversation?.id

        const key = Object.values(conversation)[0];
        draft[provider].users[` ${key}`].unseen_messages_count = 0;
        if (draft[provider]) {
          if (draft[provider].users[` ${key}`])
            draft[provider].users[` ${key}`].unseen_messages_count = 0;
        }

        draft.meta = { action: ACTIVATE_CONVERSATION, error: null };
        break;
      }

      case DEACTIVATE_CONVERSATION: {
        const { provider } = payload;
        // live chat
        if (draft[provider]) {
          draft[provider].liveChat = null;
        } else if (draft[provider.name]) {
          draft[provider.name].liveChat = null;

        }
        break;
      }
      case CLEAR_CONVERSATIONS_LIST: {
        const { provider } = payload;
        // conversation list
        if (draft[provider]) draft[provider].users = null;
        break;
      }

      case ADD_NOTE_TO_PERSON_FROM_CONVERSATION: {
        const { personId, data, provider } = payload;

        if (
          Number(personId) !==
          state[provider].liveChat?.attributes?.user_info?.data
            ?.attributes?.id
        ) {
          return state;
        }

        draft[provider].liveChat.attributes.last_note.data = data;
        draft.meta = {
          action: ADD_NOTE_TO_PERSON_FROM_CONVERSATION,
          error: null,
        };
        break;
      }

      case ADD_UPCOMING_MESSAGE_TO_CONVERSATION: {
        const { provider, conversation_id, message } = payload;

        const isConversationSameWithLiveChat =
          draft[provider].liveChat?.attributes?.id ===
          Number(conversation_id);

        if (isConversationSameWithLiveChat) {
          // livechat
          // transform conversation if done to open
          if (draft[provider].liveChat.attributes.statue === 'done')
            draft[provider].liveChat.attributes.statue = 'open';

          const my_message = {};

          my_message[message.attributes.custom_date] = [
            message.attributes,
          ];

          let custom_date_messages =
            draft[provider].liveChat.attributes.messages.messages[
            message.attributes.custom_date
            ];

          custom_date_messages.unshift(message.attributes);

          const my_new_messages = {};

          my_new_messages[message.attributes.custom_date] =
            custom_date_messages;

          draft[provider].liveChat.attributes.messages.messages = {
            ...draft[provider].liveChat.attributes.messages.messages,
            ...my_new_messages,
          };

          draft[provider].liveChat.attributes.last_message.data = {
            ...message,
            isFresh: false,
          };

          // users
          // transform conversation if done to open
          if (
            draft[provider].users[` ${conversation_id}`].statue ===
            'done'
          )
            draft[provider].users[` ${conversation_id}`].statue =
              'open';

          draft[provider].users[
            ` ${conversation_id}`
          ].attributes.last_message.data = {
            ...message,
            isFresh: false,
          };
        } else {
          // users && not in the live_chat
          // transform conversation if done to open
          if (
            draft[provider].users[` ${conversation_id}`].statue ===
            'done'
          )
            draft[provider].users[` ${conversation_id}`].statue =
              'open';

          draft[provider].users[
            ` ${conversation_id}`
          ].attributes.last_message.data = {
            ...message,
            isFresh: true,
          };
        }
        draft.meta = {
          action: ADD_UPCOMING_MESSAGE_TO_CONVERSATION,
          error: null,
        };
        break;
      }

      case TAKE_OVER: {
        const { conversationId, provider, userId } = payload;

        // userId
        draft[provider].users[conversationId].user_id = userId;

        draft[provider].users[conversationId].attributes.user_id =
          userId;
        // live chat
        if (
          Number(conversationId) ===
          draft[provider].liveChat.attributes.id
        )
          draft[provider].liveChat.attributes.user_id = userId;
        // meta
        draft.meta = {
          action: TAKE_OVER,
          error: null,
        };
        break;
      }

      case UN_TAKE_OVER: {
        const { conversationId, provider } = payload;

        // users
        draft[provider].users[conversationId].user_id = null;
        draft[provider].users[conversationId].attributes.user_id = null;
        // live chat
        draft[provider].liveChat.attributes.user_id = null;
        draft.meta = {
          action: UN_TAKE_OVER,
          error: null,
        };
        break;
      }

      case TAKE_OVER_FAILED: {
        draft.meta = {
          action: TAKE_OVER_FAILED,
          error,
        };
        break;
      }

      case MARK_LAST_MESSAGE_AS_OLD: {
        const { provider, conversation_id } = payload;

        // users
        draft[provider].users[
          conversation_id
        ].attributes.last_message.data.isFresh = false;
        draft.meta = {
          action: MARK_LAST_MESSAGE_AS_OLD,
          error: null,
        };
        break;
      }

      case GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS: {
        const { members } = payload;

        // users
        draft.members = members;
        draft.meta = {
          action: GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS,
          error: null,
        };
        break;
      }

      case GET_LIVE_MODE_VALUE: {
        const { live_mode } = payload;
        draft.isLiveMode = live_mode;
        break;
      }

      case UPDATE_LIVE_MODE_VALUE: {
        const { live_mode } = payload;
        draft.isLiveMode = live_mode;
        break;
      }

      case UPDATE_CONVERSATION_SENTIMENT: {
        const { conversation, provider, filter_match, live_mode } = payload;
        if (filter_match && live_mode) {
          draft[provider].users = {
            ...draft[provider].users,
            ...conversation,
          };
        }

        draft.meta = { action: UPDATE_CONVERSATION_SENTIMENT, error: null };
        break;
      }

      default:
        // by default immer return  original state
        break;
    }
  });
