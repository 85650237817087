/* eslint-disable no-case-declarations */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-const */

import axios from 'helpers/axios';
import originalAxio from 'axios';
import {
  DELETE_INTENT,
  FAQS_ERROR,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORY_INTENTS,
  FLIP_INTENT_STATUS,
  ADD_NEW_CATEGORY,
  ADD_NEW_INTENT,
  ACTIVATE_INTENT,
  GET_ALL_INTENT,
  RESET_INTENTS_STATE,
  DELETE_FAQ,
  ADD_NEW_FAQ,
  UPDATE_FAQ,
  RESET_ACTIVE_INTENT,
  DELETE_REPLY,
  UPDATE_REPLY,
  ADD_REPLY,
  UPDATE_INTENT,
  UPDATE_CATEGORY,
  GET_ALL_INTENT_REQUEST,
  GET_ALL_CATEGORY_INTENT_REQUEST,
  DELETE_INTENT_CATEGORY,
  DELETE_INTENT_CATEGORY_FAIL,
  ADD_NEW_CATEGORY_FAIL,
  INTENT_UPDATE_FAIL,
  DELETE_INTENT_FAIL,
  ADD_NEW_FAQ_FAIL,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ_FAIL,
  ADD_REPLY_FAIL,
  DELETE_REPLY_FAIL,
  UPDATE_REPLY_FAIL,
  SET_ACTIVE_TAB,
  SET_DEFAULT_CATEGORY,
  SET_DEFAULT_LANGUAGE,
  CHANGE_INTENT_PERCENTAGE_SUCCESS,
  GET_INTENT_PERCENTAGE_SUCCESS,
  ADD_NEW_BULK_FAQ,
  ADD_NEW_BULK_FAQ_FAIL,
  // clear active intent
  CLEAR_ACTIVE_INTENT_SUCCESS,
  EXPORT_INTENTS_ACTION,
  BULK_DELETE_INTENT,
} from 'store/actions/types/faqs';
import { botBuilderPath } from 'constants/apis';

import IntentModel from 'models/IntentModel';
import FAQModel from 'models/FAQModel';
import ReplyModel from 'models/ReplyModel';
import apiRequest, { getErrorMessageAndCode } from 'helpers/apiRequest';
import { SET_BOT_BUILDER_TAB } from '../types/tabs';

// clear active intent
export const clearActiveIntentAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ACTIVE_INTENT_SUCCESS
    })
  }
}

export const getAllCategoriesAction = (botBuilderAppId, setUiLoadingError) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      const categories = [];
      responseData.data.forEach((item) => {
        const category = {
          id: item.id,
          name: item.attributes.name,
        };
        categories.push(category);
      });
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: {
          categories,
        },
      });
    };
    const onError = (e) => {
      dispatch({
        type: FAQS_ERROR,
      });
    };
    apiRequest.get({
      url: `/api/${botBuilderAppId}/intent_categories/`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

/**
 *
 * @param categoryId
 * @param botBuilderAppId
 * @param payload
 */
export const updateCategoryAction = ({
  categoryId,
  botBuilderAppId,
  payload,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Edited Category successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const updatedCategory = {
        id: responseData.data.id,
        name: responseData.data.attributes.name,
      };
      dispatch({
        type: UPDATE_CATEGORY,
        payload: {
          updatedCategory,
        },
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: FAQS_ERROR,
      });
    };
    apiRequest.patch({
      url: `/api/${botBuilderAppId}/intent_categories/${categoryId}`,
      bodyData: payload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

/**
 *
 * @param categoryId
 * @param botBuilderAppId
 * @param lang
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllCategoryIntents = ({
  botBuilderAppId,
  categoryId,
  lang,
  keyword,
  page = 1,
  perPage = 25,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      const categoryIntents = [];
      let categoryLang;
      responseData.data.intents.forEach((item) => {
        let faqs = [];
        let replies = [];

        item.attributes.faqs.forEach((faq) => {
          faqs.push(
            new FAQModel({
              id: faq.id,
              body: faq.body,
            })
          );
        });

        item.attributes.intent_replies.forEach((reply) => {
          replies.push(
            new ReplyModel({
              id: reply.id,
              replyType: reply.reply_type,
              data: reply.data,
              intent: item,
            })
          );
        });

        const intent = {
          id: item.id,
          name: item.attributes.name,
          status: item.attributes.is_active,
          updatedAt: item.attributes.updated_at,
          faqs: faqs,
          replies: replies,
          category: item.attributes.intent_category,
          lang: item.attributes.lang,
        };
        categoryIntents.push(intent);
      });

      dispatch({
        type: GET_ALL_CATEGORY_INTENTS,
        payload: {
          intents: categoryIntents,
          categoryIntentsCount: responseData.data.count,
          categoryIntentsFilteredCount:
            responseData.data.filtered_count,
        },
      });
    };
    const onError = (e) => {
      dispatch({ type: FAQS_ERROR, payload: { e } });
      // throw new Error(error.message.toString());
    };

    // TODO: pending on the backend to filter only with lang

    if (categoryId === null) {
      apiRequest.get({
        url: `/api/${botBuilderAppId}/intents?lang=${lang}&keyword=${keyword}&page=${page}&per_page=${perPage}`,
        onSuccess,
        onError,
        setUiLoadingError,
      });
    } else {
      apiRequest.get({
        url: `/api/${botBuilderAppId}/intents?intent_category_id=${categoryId}&lang=${lang}&keyword=${keyword}&page=${page}&per_page=${perPage}`,
        onSuccess,
        onError,
        setUiLoadingError,
      });
    }
  };
};

export const deleteFaqAction = ({
  faqId,
  intentId,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`FAQ deleted successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_FAQ,
        payload: { deletedFaq: responseData.data },
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_FAQ_FAIL,
      });
    };
    apiRequest.delete({
      url: `/api/${intentId}/faqs/${faqId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

/**
 * This action to handle intents panel ...
 * @param categoryId
 * @returns {(function(*): void)|*}
 */

export const activateIntentAction = (intentId, botBuilderId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/api/${botBuilderId}/intents/${intentId}`
      );

      let faqs = [];
      let replies = [];

      response.data.data.attributes.faqs.forEach((faq) => {
        faqs.push(
          new FAQModel({
            id: faq.id,
            body: faq.body,
          })
        );
      });
      response.data.data.attributes.intent_replies.forEach((reply) => {
        replies.push(
          new ReplyModel({
            id: reply.id,
            replyType: reply.reply_type,
            data: reply.data,
            intent: response.data.data,
          })
        );
      });

      const activatedIntent = new IntentModel({
        id: response.data.data.id,
        name: response.data.data.attributes.name,
        status: response.data.data.attributes.is_active,
        updatedAt: response.data.data.attributes.updated_at,
        faqs: faqs,
        replies: replies,
        category: response.data.data.attributes.intent_category,
        lang: response.data.data.attributes.lang,
      });

      dispatch({
        type: ACTIVATE_INTENT,
        payload: { activatedIntent: activatedIntent },
      });
    } catch (error) {
      throw new Error(error.message.toString());
    }
  };
};

export const addNewFaqAction = ({
  intentId,
  body,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      const faq = new FAQModel({
        id: Number(responseData.data.id),
        body: responseData.data.attributes.body,
        intent: responseData.data.attributes.intent,
      });
      dispatch({ type: ADD_NEW_FAQ, payload: { faq } });
      enqueueSnackbar(`intent Created successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: ADD_NEW_FAQ_FAIL,
      });
    };
    apiRequest.post({
      url: `/api/${intentId}/faqs`,
      bodyData: { body },
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const addBulkFaqsAction = ({
  intentId,
  body,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      // TODO: loop through returned faqs and pass it to the reducer
      const faqs = responseData.data.map((fa) => ({
        id: Number(fa.id),
        body: fa.attributes.body,
        intent: fa.attributes.intent,
      }));

      dispatch({ type: ADD_NEW_BULK_FAQ, payload: { faqs } });
      enqueueSnackbar(`intent Created successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: ADD_NEW_BULK_FAQ_FAIL,
      });
    };
    apiRequest.post({
      url: `/api/${intentId}/faqs`,
      bodyData: { body },
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const updateFaqAction = ({
  intentId,
  faq,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      const updatedFaq = new FAQModel({
        id: Number(responseData.data.id),
        body: responseData.data.attributes.body,
        intent: responseData.data.attributes.intent,
      });
      dispatch({ type: UPDATE_FAQ, payload: { faq: updatedFaq } });
      enqueueSnackbar(`intent updated successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: UPDATE_FAQ_FAIL,
      });
    };
    apiRequest.put({
      url: `/api/${intentId}/faqs/${faq?.id}`,
      bodyData: { body: faq.body },
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

/**
 *
 * @param botBuilderAppId
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllIntentsAction = ({
  botBuilderAppId,
  page = '',
  perPage = '',
}) => {
  return async (dispatch) => {
    try {
      const allIntents = [];
      const response = await axios.get(
        `/api/${botBuilderAppId}/intents?page=${page}&per_page=${perPage}`
      );

      response.data.data.intents.forEach((item) => {
        let faqs = [];
        let replies = [];

        item.attributes.faqs.forEach((faq) => {
          faqs.push(
            new FAQModel({
              id: faq.id,
              body: faq.body,
            })
          );
        });

        item.attributes.intent_replies.forEach((reply) => {
          replies.push(
            new ReplyModel({
              id: reply.id,
              replyType: reply.reply_type,
              data: reply.data,
              intent: item,
            })
          );
        });

        const intent = new IntentModel({
          id: item.id,
          name: item.attributes.name,
          status: item.attributes.is_active,
          updatedAt: item.attributes.updated_at,
          faqs: faqs,
          replies: replies,
          category: item.attributes.intent_category,
          lang: item.attributes.lang,
        });
        allIntents.push(intent);
      });
      dispatch({
        type: GET_ALL_INTENT,
        payload: {
          Intents: allIntents,
          count: response.data.data.count,
          filteredCount: response.data.data.filtered_count,
        },
      });
    } catch (error) {
      dispatch({ type: FAQS_ERROR, payload: { error } });
    }
  };
};

/**
 *
 * @param intentId
 * @param botBuilderAppId
 * @returns {(function(*): Promise<void>)|*}
 */
export const deleteIntentAction = ({
  intentId,
  botBuilderAppId,
  history,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Intent deleted successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const deletedIntent = new IntentModel({
        id: responseData.data.id,
      });
      dispatch({ type: DELETE_INTENT, payload: { deletedIntent } });
      dispatch({ type: SET_BOT_BUILDER_TAB, payload: 1 });

      history.push(`/talkybots/${botBuilderAppId}/bot-builder`);
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_INTENT_FAIL,
      });
    };
    apiRequest.delete({
      url: `/api/${botBuilderAppId}/intents/${intentId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const DeleteIntentCategoryAction = (
  categorytId,
  botBuilderAppId,
  setUiLoadingError,
  enqueueSnackbar
) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`delete  Category successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const deletedCategory = {
        id: responseData.data.id,
      };
      dispatch({
        type: DELETE_INTENT_CATEGORY,
        payload: { deletedCategory },
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: FAQS_ERROR,
      });
    };
    apiRequest.delete({
      url: `/api/${botBuilderAppId}/intent_categories/${categorytId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

/**
 *
 * @param intentId
 * @param botBuilderAppId
 * @param prevStatus
 */
export const flipIntentStatusAction = ({
  intentId,
  botBuilderAppId,
  prevStatus,
}) => {
  return async (dispatch) => {
    try {
      if (intentId === null || intentId === undefined) {
        throw new Error('Intent Must Exists !');
      }

      dispatch({ type: FLIP_INTENT_STATUS, payload: { intentId } });

      // Optimistic Update
      await axios.patch(`/api/${botBuilderAppId}/intents/${intentId}`, {
        is_active: !prevStatus,
      });
    } catch (error) {
      dispatch({ type: FLIP_INTENT_STATUS, payload: { intentId } });
      // throw new Error(error.message.toString());
    }
  };
};

/**
 *
 * @param intentId
 * @param botBuilderAppId
 * @param prevStatus
 */
export const updateIntentAction = ({
  intentId,
  botBuilderAppId,
  payload,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      let faqs = [];
      let replies = [];

      responseData.data.attributes.faqs.forEach((faq) => {
        faqs.push(
          new FAQModel({
            id: faq.id,
            body: faq.body,
            intent: responseData.data,
          })
        );
      });

      responseData.data.attributes.intent_replies.forEach((reply) => {
        replies.push(
          new ReplyModel({
            id: reply.id,
            replyType: reply.reply_type,
            data: reply.data,
            intent: responseData.data,
          })
        );
      });

      const updatedIntent = new IntentModel({
        id: responseData.data.id,
        name: responseData.data.attributes.name,
        status: responseData.data.attributes.is_active,
        updatedAt: responseData.data.attributes.updated_at,
        category: responseData.data.attributes.intent_category,
        lang: responseData.data.attributes.lang,
        faqs: faqs,
        replies: replies,
      });
      dispatch({ type: UPDATE_INTENT, payload: { updatedIntent } });
      enqueueSnackbar(`intent updated successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: INTENT_UPDATE_FAIL,
      });
    };
    apiRequest.patch({
      url: `/api/${botBuilderAppId}/intents/${intentId}`,
      bodyData: payload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const addNewCategoryAction = ({
  formData,
  botBuilderId,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Created Category successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      dispatch({
        type: ADD_NEW_CATEGORY,
        payload: {
          category: {
            id: responseData?.data?.id,
            name: responseData?.data?.attributes?.name,
          },
        },
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      // dispatch({
      // 	type: FAQS_ERROR,
      // });
    };
    apiRequest.post({
      url: `/api/${botBuilderId}/intent_categories/`,
      bodyData: formData,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const resetFaqsStateAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INTENTS_STATE });
  };
};

// Replies Actions

/**
 *
 * @param id
 * @param intentId
 * @returns {(function(*): Promise<void>)|*}
 */

export const deleteReplyAction = ({
  ReplyId,
  intentId,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Updated reply successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const deletedReply = new ReplyModel({
        id: responseData.data.id,
        replyType: responseData.data.attributes.reply_type,
        data: responseData.data.attributes.data,
        intent: responseData.data.intent,
      });
      dispatch({ type: DELETE_REPLY, payload: { deletedReply } });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_REPLY_FAIL,
      });
    };

    apiRequest.delete({
      url: `/api/${intentId}/intent_replies/${ReplyId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const updateReplyAction = ({
  replyId,
  intentId,
  replyPayload,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Updated reply successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const updatedReply = new ReplyModel({
        id: responseData.data.id,
        replyType: responseData.data.attributes.reply_type,
        data: responseData.data.attributes.data,
        intent: responseData.data.attributes.intent,
      });
      dispatch({ type: UPDATE_REPLY, payload: { updatedReply } });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: UPDATE_REPLY_FAIL,
      });
    };

    apiRequest.put({
      url: `/api/${intentId}/intent_replies/${replyId}`,
      bodyData: replyPayload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const addNewReplyAction = ({
  intentId,
  replyPayload,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Created reply successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const addedReply = new ReplyModel({
        id: responseData.data.id,
        replyType: responseData.data.attributes.reply_type,
        data: responseData.data.attributes.data,
        intent: responseData.data.attributes.intent,
      });
      dispatch({ type: ADD_REPLY, payload: { addedReply } });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: ADD_REPLY_FAIL,
      });
    };

    apiRequest.post({
      url: `/api/${intentId}/intent_replies`,
      bodyData: replyPayload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

export const resetActiveIntentAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ACTIVE_INTENT });
  };
};

/*
[ℹ️] It's purpose is : when go back from e.i (intents faqs) 
to botbuilder Tabs * .... intents tabe is active and so on
*/
export const setActiveTabeAction = (activeTab) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_TAB, payload: { activeTab } });
  };
};

export const addNewIntentAction = ({
  intent,
  history,
  botBuilderAppId,
  talkybotId,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const requestPayload = {
      name: intent.name,
      intent_category_id: intent.categoryId,
      lang: intent.lang,
      is_active: intent.isActive,
    };

    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Created Intent successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      const newIntent = new IntentModel({
        id: responseData.data.id,
        name: responseData.data.attributes.name,
        status: responseData.data.attributes.is_active,
        updatedAt: responseData.data.attributes.updated_at,
        category: responseData.data.attributes.intent_category,
        lang: responseData.data.attributes.lang,
      });

      dispatch({
        type: ADD_NEW_INTENT,
        payload: {
          intent: newIntent,
        },
      });
      /**
       * active intent to track the current active intent
       * so that we can use it in the reply and faq page
       */
      dispatch({
        type: ACTIVATE_INTENT,
        payload: { activatedIntent: newIntent },
      });
      // >> Move to the Faqs page
      // history.push(
      // 	`/talkybots/${talkybotId}/bot-builder/${newIntent.id}/faqs/create`
      // );
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({ type: FAQS_ERROR, payload: { e } });
    };

    apiRequest.post({
      url: `/api/${botBuilderAppId}/intents`,
      bodyData: requestPayload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

// set default category
export const setDefaultCategoryAction = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: SET_DEFAULT_CATEGORY, payload: { categoryId } });
  };
};

// set default language
export const setDefaultLanguageAction = (lang) => {
  return (dispatch) => {
    dispatch({ type: SET_DEFAULT_LANGUAGE, payload: { lang } });
  };
};
// TODO Create reducer for setting
export const changeIntentPercentageAction = ({
  botBuilderAppId,
  value,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      dispatch({
        type: CHANGE_INTENT_PERCENTAGE_SUCCESS,
        payload: {
          value: parseInt(responseData.data.oss_thresh * 100, 10),
        },
      });

      enqueueSnackbar('Intent Percentage updated successfully', {
        variant: 'success',
        autoHideDuration: 2500,
      });
    };
    const onError = (e) => {
      enqueueSnackbar('Please enter a valid value between 0 and 1', {
        variant: 'error',
        autoHideDuration: 2500,
      });
    };

    apiRequest.put({
      url: `${botBuilderPath}/${botBuilderAppId}`,
      bodyData: {
        oss_thresh: Number(value) / 100,
      },
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};
// TODO Create reducer for setting
export const getIntentPercentageAction = (
  botBuilderAppId,
  setUiLoadingError = () => { }
) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      dispatch({
        type: GET_INTENT_PERCENTAGE_SUCCESS,
        payload: {
          value: parseInt(responseData.oss_thresh * 100, 10),
        },
      });
    };
    const onError = (e) => { };

    apiRequest.get({
      url: `${botBuilderPath}/${botBuilderAppId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};


export const exportIntentsAction = ({
  botBuilderAppId,
  lang,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    try {
      setUiLoadingError({ loading: true, error: null });
      const response = await axios.get(`${botBuilderPath}/${botBuilderAppId}/reports/${lang}/export_intents`, {
        responseType: 'blob' 
      });
      setUiLoadingError({
				loading: false,
				success: response ? response : true,
				error: null,
			});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'intents.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch({
        type: EXPORT_INTENTS_ACTION,
      });
    } catch (error) {
      console.log('first error', error.response.status)
      enqueueSnackbar(
        error.response.status === 404 ? 
        'No data found for this language' 
        : error.message
        , {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});

    }
  };
};

export const importIntentsAction = ({
  botBuilderAppId,
  lang,
  file,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('file', file);
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Intents imported successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
    };
    apiRequest.post({
      url: `${botBuilderPath}/${botBuilderAppId}/reports/import_intents`,
      bodyData: formData,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
}

export const bulkDeleteIntentAction = ({
  bulkIds,
  botBuilderAppId,
  history,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const dataToSend = { intent_ids: bulkIds };
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Intents deleted successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const deletedIntent = new IntentModel({
        id: responseData.data.id,
      });
      dispatch({ type: BULK_DELETE_INTENT, payload: bulkIds });
      dispatch({ type: SET_BOT_BUILDER_TAB, payload: 1 });

      history.push(`/talkybots/${botBuilderAppId}/bot-builder`);
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_INTENT_FAIL,
      });
    };
    apiRequest.delete({
      url: `/api/${botBuilderAppId}/intents/bulk_destroy`,
      onSuccess,
      bodyData: dataToSend,
      onError,
      setUiLoadingError,
    });
  };
};


