export const SAVE_NEW_FLOW_STATE_REQUEST = 'SAVE_NEW_FLOW_STATE_REQUEST';
export const SAVE_NEW_FLOW_STATE_SUCCESS = 'SAVE_NEW_FLOW_STATE_SUCCESS';
export const SAVE_NEW_FLOW_STATE_FAILED = 'SAVE_NEW_FLOW_STATE_FAILED';
export const GET_CURRENT_FLOW_STATE_REQUEST = 'GET_CURRENT_FLOW_STATE_REQUEST';
export const GET_CURRENT_FLOW_STATE_SUCCESS = 'GET_CURRENT_FLOW_STATE_SUCCESS';
export const GET_CURRENT_FLOW_STATE_FAILED = 'GET_CURRENT_FLOW_STATE_FAILED';
export const CLEAR_CURRENT_FLOW_STATE = 'CLEAR_CURRENT_FLOW_STATE';
export const DELETE_NODE_FROM_PATH_IN_FLOW_SUCCESS =
	'DELETE_NODE_FROM_PATH_IN_FLOW_SUCCESS';
export const DELETE_NODE_FROM_PATH_IN_FLOW_FAIL =
	'DELETE_NODE_FROM_PATH_IN_FLOW_FAIL';
export const UPDATE_CURRENT_FLOW_STATE_SUCCESS =
	'UPDATE_CURRENT_FLOW_STATE_SUCCESS';
export const UPDATE_CURRENT_FLOW_STATE_FAILED =
	'UPDATE_CURRENT_FLOW_STATE_FAILED';
export const GET_IMAGE_URL =
	'GET_IMAGE_URL';
export const GET_IMAGE_URL_SUCCESS =
	'GET_IMAGE_URL_SUCCESS';
  export const GET_IMAGE_URL_FAIL =
	'GET_IMAGE_URL_FAIL';
  export const DELETE_IMAGE_URL =
    'DELETE_IMAGE_URL';
  export const DELETE_IMAGE_URL_SUCCESS =
    'DELETE_IMAGE_URL_SUCCESS';
  
