import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import { languagesArray } from 'constants/supportedLanguages';
import FormSelect from 'controllers/FormSelect';
import FormTextInput from 'controllers/FormTextInput';
import mapCategories from 'helpers/MapCategories';
import mapLanguages from 'helpers/mapLanguages';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

const AddList = ({
	activeTalkybot,
	handleCloseModal,
	defaultCategory,
	addNewEntityList,
	entityId,
	handleRefreshPriority,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();


	// const isEntityWithSameName = (name, allEntities) => {
	// 	return allEntities.some((entity) => entity.name === name);
	// };

	const validationSchema = yup.object().shape({
		threshold: yup.number('Threshold is required').typeError('Threshold must be a real number between 50 and 99').required('Threshold is required').min(50).max(99).nullable(),
		language: yup
			.string().required('You should select a language')
			,
	});

	const {
		handleSubmit,
		formState: { errors },
		setError,
		control,
		watch,
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			language: 0,
      threshold: 90,
		},
	});

	// 🔁 Loading and error handling
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();


		const handleSubmitCreateIntent = async (payload) => {
		if (payload.language === '0') {
			setError('language', {
				type: 'manual',
				message: 'You should select Language',
			});
			return
		}

		if (
			Object.keys(errors).length === 0 &&
			payload.language !== 0) {

			addNewEntityList({
				entityId,
				language: payload.language,
				threshold: payload.threshold,
				enqueueSnackbar,
				setUiLoadingError,
			});
		}
	};

	// dismiss the model when the backend is done
	useEffect(() => {
		if (successData) {
			handleCloseModal();
			handleRefreshPriority()
		}
	}, [successData]);

	return (
		<div>
			<form onSubmit={handleSubmit(handleSubmitCreateIntent)}>
				<GridContainer
					spacing={10}
					justifyContent="space-evenly"
					className={classes.row}
				>
					
					<GridItem md={12} xs={12}>
					<span className={classes.languageText}>Select Language</span>
						<Controller
							name="language"
							control={control}
							render={({ field }) => (
								<FormSelect
									{...field}
									error={errors?.language?.message}
									select
									smallHeight
									placeHolder="Select A language"
									// array of categories
									options={mapLanguages(languagesArray)}
									style={{
										padding: '7px',
										border: '1px solid #6418C3',
									}}
									/>
									)}
									/>
					</GridItem>
					<GridItem md={12} xs={12} style={{ display: 'flex', flexDirection: 'column'}}>
						<span className={classes.thresholdText}>Detection Threshold</span>
						<Controller
							name="threshold"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<FormTextInput
								{...field}
								error={errors?.threshold?.message}
									type="number"
									placeHolder="Write Percentage %"
									style={{margin: '20px 0px'}}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>

				<GridContainer
					justifyContent="flex-end"
					className={classes.row}
					style={{ marginTop: '40px', marginBottom: '20px' }}
				>
					<Button
						onClick={() => handleCloseModal()}
						style={{ marginRight: '20px', padding: '10px' }}
					>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit">
						{isLoading ? (
							<CircularProgress color="white" size={15} />
						) : (
							'Add'
						)}
					</Button>
				</GridContainer>
			</form>
		</div>
	);
};


export default AddList

const useStyles = makeStyles((theme) => ({
	addEntity: {
		borderRadius: '1rem',
		padding: '0.5rem 2rem',
		'&:hover': {
			backgroundColor: '#B77DFF',
		},
	},
	languageText: {
		fontSize: '14px',
		fontWeight: '600',
	},
	thresholdText: {
		fontSize: '14px',
		fontWeight: '600',
		margin: '16px 0px'
	},
}));
