import { v1 } from 'uuid';
import {
  TEXT_MESSAGE,
  TRIGGER_ANOTHER_TOPIC_FLOW,
  END_WITH_MESSAGE,
  SENDING_MESSAGE_WITH_OPTIONS,
  GUIDE_MESSAGE,
  SET_USER_ATTRIBUTE,
  NOTIFY_TEAM_MEMEBER_TO_TAKE_OVER,
  ASSIGN_TO_TEAM_MEMBER,
  STARTING_MESSAGE,
  SAVE_USER_ATTRIBUTE,
  MESSAGE_WITH_QUICK_REPLIES,
  JSONAPI,
  // scenario nodes
  MARK_CONVERSATION_AS_DONE,
  START_SCENARIO,
  END_SCENARIO,
  // change Status
  CHANGE_STATUS,
  // take a note
  TAKE_A_NOTE,
  // feed back
  FEEDBACK,
  // Json Component
  JSON_COMPONENT,
  SEND_CAROUSEL,
} from 'constants/nodesTypes';
import * as yup from 'yup';
import "yup-phone";

export const generateStartMessageScheme = () => {
  return {
    node_type: STARTING_MESSAGE,
    data: {
      text: 'Hi There! I’ll Be Helping You Today ',
      mentions: [],
    },
  };
};
export const generateStartMessageValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required'),
  });
};
export const getStartMessageInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
  };
};
/**  end */

/**
 * Generate text message backend node scheme
 *
 * @returns {{custom_node_id: *, node_type: string, data: {text}, next_node_id: string}}
 */

export const generateGuideMessageScheme = () => {
  return {
    node_type: GUIDE_MESSAGE,
    data: {
      text: 'Click To Add Button',
      // mentions: [],
    },
  };
};

/**
 * Generate text message backend node scheme
 *
 * @returns {{node_type: string, data: {text}}}
 */

export const generateTextMessageScheme = () => {
  return {
    node_type: TEXT_MESSAGE,
    data: {
      text: '',
      mentions: [],
    },
  };
};
export const generateTextMessageValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
  });
};
export const getInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
  };
};
/** ****** end text message */

/*********************************** Start Scenario nodes ***********************************/
/***************************************************************************************** */

/************** Start Done node **********/
export const generateDoneNodeSchema = () => {
  return {
    node_type: MARK_CONVERSATION_AS_DONE,
  };
};
/************** End Done node **********/
/************** Start (Start Scenario) node **********/
export const generateStartScenarioSchema = () => {
  return {
    node_type: START_SCENARIO,
    data: {
      scenario: '',
      question: '',
      options: [
        {
          title: '',
          decision: '',
          topic_flow: '',
        },
      ],
    },
  };
};
export const generateDefaultScenarioOptionSchema = () => {
  return {
    title: '',
    decision: '',
    topic_flow: '',
  };
};
const optionsNamesSchema = {
  title: yup.string().required('This field is required').min(3, 'Name should be at least 3 characters').max(30, 'Name should be at most 30 characters'),
  // topic_flow: yup.object().required('This field is required').nullable(),
};
export const generateStartScenarioValidationSchema = () => {
  return yup.object().shape({
    scenario: yup.object().required('This field is required').nullable(),
    question: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    options: yup
      .array()
      .of(yup.object().shape(optionsNamesSchema))
      .required('Must have fields')
      .min(1, 'Minimum of 1 field'),
  });
};
export const generateStartScenarioInputNamesSchema = () => {
  return {
    scenario: 'scenario',
    question: 'question',
    options: 'options',
    optionsNamesSchema: {
      title: 'title',
      decision: 'decision',
      topic_flow: 'topic_flow'
    },
  };
};
/************** End (Start Scenario) node **********/
/************** Start (End Scenario) node **********/
export const generateEndScenarioSchema = () => {
  return {
    node_type: END_SCENARIO,
    data: {
      scenario: '',
      incomplete_scenario: false,
    },
  };
};
export const generateEndScenarioValidationSchema = () => {
  return yup.object().shape({
    scenario: yup.object().required('This field is required').nullable(),
  });
};
export const generateEndScenarioInputNamesSchema = () => {
  return {
    scenario: 'scenario',
    incomplete_scenario: 'incomplete_scenario'
  };
};
/************** End (End Scenario) node **********/

/***************************************************************************************** */
/*********************************** end Scenario nodes ***********************************/

/*********************************** Start Change Status node ******************************/
export const generateChangeStatusScheme = () => {
  return {
    node_type: CHANGE_STATUS,
    data: {
      status: '',
    },
  };
};
export const generateChangeStatusValidationSchema = () => {
  return yup.object().shape({
    status: yup.object().required('This field is required').nullable(),
  });
};
export const generateChangeStatusInputNamesSchema = () => {
  return {
    status: 'status',
  };
};
/*********************************** End Change Status node *******************************/
/*********************************** Start Take A Note node *******************************/
/**
 * @returns {{node_type: string, data: {text}}}
 */

export const generateTakeANoteScheme = () => {
  return {
    node_type: TAKE_A_NOTE,
    data: {
      text: '',
      mentions: [],
    },
  };
};
export const generateTakeANoteValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required'),
  });
};
export const generateTakeANoteInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
  };
};
/*********************************** End Take A Note node *******************************/

/**
 * Generate sending message with options backend node scheme
 * @returns {{node_type: string, data: {buttons: *[], text: string}}}
 */

export const generateSendingMessageWithOptionsScheme = () => {
  return {
    node_type: SENDING_MESSAGE_WITH_OPTIONS,
    data: {
      text: '',
      save_answer: false,
      custom_field: '',
      buttons: [
        {
          type: 'postback',
          title: '',
          payload: '',
        },
      ],
      mentions: [],
    },
  };
};
export const generateDefaultOptionValueSchema = () => {
  return {
    type: 'postback',
    title: '',
    payload: '',
  };
};
const buttonsNamesSchema = {
  title: yup.string().required('This field is required ').matches(/^(?!\s+$).*/, 'Text cannot be just spaces').max(24, 'Must be at most 24 characters'),
  payload: yup.object().required('This field is required').nullable(),
};
export const generateSendingMessageWithOptionsValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    save_answer: yup.boolean(),
    custom_field: yup
      .object()
      .nullable()
      .when('save_answer', {
        is: true,
        then: yup
          .object()
          .required('This field is required')
          .nullable(),
      }),
    buttons: yup
      .array()
      .of(yup.object().shape(buttonsNamesSchema))
      .required('Must have fields')
      .min(1, 'Minimum of 1 field'),
  });
};
export const getSendingMessageWithOptionsInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
    save_answer: 'save_answer',
    custom_field: 'custom_field',
    buttons: 'buttons',
    buttonsNamesSchema: {
      type: 'type',
      title: 'title',
      payload: 'payload',
    },
  };
};
/** ***** end */

/**
 * Generate Trigger another topic flow back end node scheme
 * @param flowId
 * @returns {{node_type: string, data: {topic_flow_id}}}
 */

export const generateTriggerAnotherTopicFlowScheme = () => ({
  node_type: TRIGGER_ANOTHER_TOPIC_FLOW,
  data: {
    topic_flow_id: '',
  },
});
export const generateTriggerAnotherFlowValidationScheme = () => {
  return yup.object().shape({
    topic_flow_id: yup
      .object()
      .required('This field is required')
      .nullable(),
  });
};
export const getTriggerAnotherFlowInputNamesSchema = () => {
  return {
    topic_flow_id: 'topic_flow_id',
  };
};
/** ******  end ***********/
/**
 * Generate End with message node scheme
 * @returns {{node_type: string, data: {text}}}
 */
export const generateEndWithMessageScheme = () => ({
  node_type: END_WITH_MESSAGE,
  data: {
    text: '',
    mentions: [],
  },
});
export const generateEndWithMessageValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required'),
  });
};
export const getEndWithMessageInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
  };
};
/** *************** end  ***************/

export const generateSetUserAttributeScheme = () => {
  return {
    node_type: SET_USER_ATTRIBUTE,
    data: {
      person_custom_field_id: '',
      value: '',
    },
  };
};
// ------ 🃏🚀 Start JsonSchema ------
/**
 * Generate  Json node scheme
 * @returns {{node_type: string, data: {
 *	url: string,
 *	method: string,
 *	headers: *[],
 *	body: string,
 * 	mentions: []
 * }}}
 */
// Default Values for Json node
;
// validation schema for Json node
// const requestParamSchema = {
// 	key: yup.string().required('Key is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),

// 	value: yup
// 		.string()
// 		.required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces')
// 		.when('key', (key, schema) => {
// 			return key
// 				? schema.required('value is required')
// 				: schema.notRequired();
// 		}),
// };

const requestParamSchema = {
  key: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
  value: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
};

requestParamSchema.key = requestParamSchema.key.test({
  test: function (key) {
    const value = this.parent.value;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});

requestParamSchema.value = requestParamSchema.value.test({
  test: function (value) {
    const key = this.parent.key;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});

// header schema for Json node
// const headerSchema = {
// 	key: yup.string().notRequired(),

// 	value: yup
// 		.string()
// 		.required('This field is required')
// 		.when('key', (key, schema) => {
// 			return key
// 				? schema.required('value required')
// 				: schema.notRequired();
// 		}),
// };

const headerSchema = {
  key: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
  value: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
};

headerSchema.key = headerSchema.key.test({
  test: function (key) {
    const value = this.parent.value;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});

headerSchema.value = headerSchema.value.test({
  test: function (value) {
    const key = this.parent.key;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});
// body schema for Json node
// const bodySchema = {
// 	// key is required when keyValue exists
// 	key: yup.string(),
// 	value: yup
// 		.string()
// 		.required('This field is required')
// 		.when('key', (key, schema) => {
// 			// key is not empty string or null
// 			return key
// 				? schema.required('body is required')
// 				: schema.notRequired();
// 		}),
// };


const bodySchema = {
  key: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
  value: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
};

bodySchema.key = bodySchema.key.test({
  test: function (key) {
    const value = this.parent.value;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});

bodySchema.value = bodySchema.value.test({
  test: function (value) {
    const key = this.parent.key;
    if ((key && !value) || (!key && value)) {
      return this.createError({
        message: 'Key and Value must either both be present or both be absent',
      });
    }
    return true;
  },
});
// schema fields names

export const generateDefaultpathSchema = () => {
  return {
    json_path: '',
    variation: ''
  };
};
export const generateDefaultCaseSchema = () => {
  return {
    name: '',
    from: '',
    to: '',
    paths: [
      {
        json_path: '',
        variation: ''
      }
    ]
  }
};
export const generateJsonApiSchema = () => {
  return {
    node_type: JSONAPI,
    data: {
      request_type: {
        field: 'GET',
        label: 'GET',
      },
      url: '',
      params: [
        {
          key: '',
          value: '',
        },
      ],
      headers: [
        {
          key: '',
          value: '',
        },
      ],
      body: [
        {
          key: '',
          value: '',
        },
      ],
      response_mapping: [
        {
          json_path: '',
          custom_field: {
            id: '',
            type: '',
            field: '',
          },
        },
      ],

      response_cases: [
        {
          name: '',
          from: '',
          to: '',
          paths: [
            {
              json_path: '',
              variation: '',

            },
          ]
        },
      ],
      response_error: {
        decision_type: 'continue-Flow',
        topic_flow: {},
      },
    },
  };
}
export const getJsonApiInputNamesSchema = () => {
  return {
    request_type: 'request_type',
    url: 'url',
    request_params: 'params',
    headers: 'headers',
    body: 'body',
    response_mapping: {
      response_mapping: 'response_mapping',
      custom_field: 'custom_field',
      json_path: 'json_path',
    },
    cases: 'response_cases',
    time_out: 'time_out',
    response_cases: {
      name: 'name',
      from: 'from',
      to: 'to',
      paths: 'paths'
    },
    paths: "paths",
    case_paths: {
      json_path: 'json_path',
      variation: 'variation'
    },


    // response_error: 'response_error',
    response_error: {
      response_error: 'response_error',
      decision_type: 'decision_type',
      topic_flow: 'topic_flow',
    },
  };
};
// validation schema for Json node [json_path]
const responsMappingSchema = {
  // json_path: yup.string(),
  // custom_field: yup.object(),
  // .required('The two field pairs are required')
  // .nullable()
  // .when('json_path', (json_path, schema) => {
  // 	// json_path is not empty string or null
  // 	return json_path
  // 		? schema.required('custom field is required')
  // 		: schema.notRequired();
  // }),
  json_path: yup.string().required('This field is required'),
  custom_field: yup.object().when(
    'json_path',
    (`${getJsonApiInputNamesSchema().response_mapping.json_path}`,
    {
      is: (val) => val !== '',
      then: yup.object().required('This field is required'),
      otherwise: yup.object().notRequired(),
    })
  ),
};
// validation schema for Json node [response_error]
const responsErrorSchema = {
  decision_type: yup
    .string()
    .required('Decision type is required')
    .oneOf(['continue-Flow', 'TopicFlow']),
  // when decision_type is TopicFlow then topic_flow is required
  // topic_flow is object of name and  id

  topic_flow: yup
    // .object({
    // 	name: yup.string().required('Name is required'),
    // 	id: yup.string().required('Id is required'),
    // })
    .object()
    .when('decision_type', (decision_type, schema) => {
      return decision_type === 'TopicFlow'
        ? schema.required('Topic Flow is required')
        : schema.notRequired();
    })
    .nullable(),
};

export const generateJsonApiValidationSchema = () => {
  return yup.object().shape({
    url: yup.string().required('Url is required').matches(/^(?!\s+$).*/, 'Url cannot be just spaces'),
    request_type: yup
      .object()
      .shape({
        field: yup.string().required('method type is required'),
        label: yup.string().required('method label is required'),
      })
      .required('field is required')
      .nullable(),
    // params: yup
    // 	.array()
    // 	.of(yup.object().shape(requestParamSchema))
    // 	.required('Must have fields'),
    // headers: yup
    // 	.array()
    // 	.of(yup.object().shape(headerSchema))
    // 	.required('Must have fields'),
    // body: yup
    // 	.array()
    // 	.of(yup.object().shape(bodySchema))
    // 	.required('Must have fields'),
    // TODO: add validation for response_mapping
    // response_mapping: yup
    // 	.array()
    // 	.of(yup.object().shape(responsMappingSchema))
    // 	.required('Must have fields'),
    response_error: yup
      .object()
      .shape(responsErrorSchema)
      .required('Must have fields'),
  });
};

// ------🃏🚀 End JsonSchema ------

/**
 *
 * @returns {{node_type: string, data: {text: string}}}
 */
export const generateSetUserAttributeValidationScheme = () => {
  return yup.object().shape({
    person_custom_field_id: yup
      .object()
      .required('This field is required')
      .nullable(),
    value: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
  });
};
export const getSetUserAttributeInputNamesSchema = () => {
  return {
    person_custom_field_id: 'person_custom_field_id',
    value: 'value',
  };
};

/**
 *
 * @param {*} teamMemberIDs
 * @returns   {{conversation_members: array}}}
 */

export const generateNotifyTeamMemberScheme = () => {
  return {
    node_type: NOTIFY_TEAM_MEMEBER_TO_TAKE_OVER,
    data: {
      text: '',
      users_to_assign: [],
    },
  };
};
export const generateNotifyToTakeOverValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().max(100).matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    users_to_assign: yup
      .array()
      .min(1, 'Please select at least one team member'),
  });
};
export const getNotifyToTakeOverInputNamesSchema = () => {
  return {
    text: 'text',
    users_to_assign: 'users_to_assign',
  };
};
/** *** end ***** */

export const generateAssignPersonToTeamMemberScheme = () => {
  return {
    node_type: ASSIGN_TO_TEAM_MEMBER,
    data: {
      text: '',
      team_member: '',
    },
  };
};
export const generateAssignPersonToTeamMemberValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().max(100).matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    team_member: yup.object().required('This field is required').nullable(),
  });
};
export const getAssignPersonToTeamMemberInputNamesSchema = () => {
  return {
    text: 'text',
    teamMember: 'team_member',
  };
};
/*************************** Start Feed Back *****************************************/
export const generateFeedBackScheme = () => {
  return {
    node_type: FEEDBACK,
    data: {
      text: '',
      mentions: [],
      feedback_type: '',
      rates: []
    },
  };
};

export const generateFeedBackValidationScheme = () => {
  return yup.object().shape({
    text: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    rate_1: yup.object().required('This field is required').nullable(),
    rate_2: yup.object().required('This field is required').nullable(),
    rate_3: yup.object().required('This field is required').nullable(),
    rate_4: yup.object().required('This field is required').nullable(),
    rate_5: yup.object().required('This field is required').nullable(),
  });
};

export const getFeedBackInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
    feedback_type: 'feedback_type',
    rate_1: 'rate_1',
    rate_2: 'rate_2',
    rate_3: 'rate_3',
    rate_4: 'rate_4',
    rate_5: 'rate_5',
  };
};
/*************************** End Feed Back *****************************************/
/*************************** Start Json Component **********************************/
export const generateJsonComponentScheme = () => {
  return {
    node_type: JSON_COMPONENT,
    data: {
      integration_id: null,
      response_error: {
        decision_type: '',
        topic_flow: null,
      },
    },
  };
};

export const generateJsonComponentValidationScheme = () => {
  return yup.object().shape({
    integration_id: yup
      .object()
      .required('This field is required')
      .nullable(),
    // decision_type: yup.string().required('This field is required'),
    // topic_flow: yup.object().required('This field is required').nullable(),
  });
};

export const getJsonComponentInputNamesSchema = () => {
  return {
    integration_id: 'integration_id',
    integration_request_type: 'integration_request_type',
    cases: 'response_cases',
    response_cases: {
      decision_type: 'decision_type',
      topic_flow: 'topic_flow',
    },
  };
};
/*************************** End Json Component ************************************/

/**
 * Generate Message with quick replies
 * @returns {{node_type: string, data: {text: string,buttons: *[],mentions: *[] }}}
 */

export const generateMessageWithQuickRepliesSchema = () => {
  return {
    node_type: MESSAGE_WITH_QUICK_REPLIES,
    data: {
      text: '',
      save_answer: false,
      custom_field: '',
      buttons: [
        {
          // type: 'test',
          title: '',
          payload: '',
        },
      ],
      mentions: [],
    },
  };
};

export const generateDefaultOptionMessageWithQuickRepliesSchema = () => {
  return {
    // type: 'test',
    title: '',
    payload: '',
  };
};

const buttonsQuickRepliesNamesSchema = {
  title: yup.string().required('This field is required ').min(1).max(24, 'Must be at most 24 characters'),
  payload: yup.object().required('This field is required').nullable(),
};

export const generateMessageWithQuickRepliesValidationScheme = () => {
  return yup.object().shape({
    cards: yup.array().of(
      yup.object().shape({
        image: yup.string().required("Image is required"),
        title: yup.string().required("Title is required"),
        subtitle: yup.string(),
        buttons: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Button name is required"),
            type: yup.string().required("Type is required"),
            payload: yup.mixed().when("type", {
              is: "topicFlow",
              then: yup.string().required("Topic flow is required"),
              otherwise: yup.string(),
            }),
          })
        ),
      })
    ),
  })
}
export const getSendingMessageWithQuickRepliesInputNamesSchema = () => {
  return {
    text: 'text',
    mentions: 'mentions',
    save_answer: 'save_answer',
    custom_field: 'custom_field',
    buttons: 'buttons',
    buttonsQuickRepliesNamesSchema: {
      type: 'type',
      title: 'title',
      payload: 'payload',
      image: 'image',
    },
  };
};
/** ***** end */

//! ***************************************************** Carousel Node Start *******************************************************! //

export const generateSendCarouselSchema = () => {
  return {
    node_type: SEND_CAROUSEL,
    data: {
      cards: [
        {
          image: null,
          title: '',
          subtitle: '',
          buttons: [
            {
              name: '',
              topic_flow: '',
              phone_number: '',
              type: 'postback',
              url: '',
            },
          ],
        }
      ]
    },
  };
};

export const generateDefaultOptionSendCarouselSchema = () => {
  return {
    type: 'postback',
    name: '',
    // payload: {},
    url: '',
    topic_flow: '',
    phone_number: ''
  };
};

const buttonsSendCarouselNamesSchema = {
  name: yup.string().required('This field is required').min(1).max(24, 'Must be at most 24 characters').test({
    name: 'notOnlySpaces',
    message: 'Name cannot be only spaces',
    test: (name) => {
      // Check if the value is not only spaces
      return !(/^\s+$/.test(name));
    },
  }),
  type: yup.string().required('Type is required'),
  url: yup.string().when('type', {
    is: 'web_url',
    then: yup.string().required('URL is required').matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Invalid URL'),
    otherwise: yup.string().nullable()
  }),
  topic_flow: yup.object().when('type', {
    is: 'postback',
    then: yup.object().required('Payload is required').nullable(),
    otherwise: yup.object().nullable()
  }), 
  phone_number: yup.string().when('type', {
    is: 'phone_number',
    then: yup.string().nullable().phone(null, true, 'Invalid phone number'),
    otherwise: yup.string().nullable()
  }),
};

export const generateSendCarouselValidationScheme = () => {
  return yup.object().shape({
    cards: yup.array().of(
      yup.object().shape({
        // image: yup.mixed().required('Image is required'),
        title: yup.string().required('Title is required').test({
          name: 'notOnlySpaces',
          message: 'Title cannot be only spaces',
          test: (title) => {
            // Check if the value is not only spaces
            return !(/^\s+$/.test(title));
          },
        }),
        subtitle: yup.string().required('Subtitle is required').test({
          name: 'notOnlySpaces',
          message: 'Subtitle cannot be only spaces',
          test: (Subtitle) => {
            // Check if the value is not only spaces
            return !(/^\s+$/.test(Subtitle));
          },
        }),
        buttons: yup.array().of(yup.object().shape(buttonsSendCarouselNamesSchema))
      })
    )
  });
};
export const getSendingSendCarouselInputNamesSchema = () => {
  return {
    cards: 'cards',
    cardsNamesSchema: {
      image: 'image',
      title: 'title',
      subtitle: 'subtitle',
      mentions: 'mentions',
      buttons: 'buttons',
      buttonsSendCarouselNamesSchema: {
        type: 'type',
        name: 'name',
        topic_flow: 'topic_flow',
        phone_number: 'phone_number',
        url: 'url'
      },
    }
  };
};

//! ***************************************************** Carousel Node End *********************************************************! //

/**
 * Generate sending save user attr backend node scheme
 *
 */

export const generateSaveUserAttributeSchema = (email_or_phone) => {
  return {
    node_type: SAVE_USER_ATTRIBUTE,
    is_waiting_for_response: true,
    data: {
      is_email_or_phone: email_or_phone ? email_or_phone : '',
      question: '',
      success_message: '',
      fallback_messages: [''],
      variation: '',
      trigger_topic_flow: { fallback_counter: null, topic_flow: null },
      notify_team_member: { fallback_counter: 0, users: [] },
      can_skip: true,
      skip_text: 'skip',
    },
  };
};

export const generateSaveUserAttributeValidationScheme = () => {
  const schema = yup.object().shape({
    is_email_or_phone: yup.string().nullable(),
    question: yup.string().required('This field is required').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    success_message: yup.string().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
    variation: yup
      .object()
      .nullable()
      .when('is_email_or_phone', {
        is: (value) => value === '' || value === null,
        then: yup
          .object()
          .required('This field is required')
          .nullable(),
      }),
    entity_id: yup
      .object()
      .nullable()
      .when('is_email_or_phone', {
        is: (value) => value === '' || value === null,
        then: yup
          .object()
          .required('This field is required')
          .nullable(),
      }),
    threshold: yup.number('Threshold is required').typeError('Threshold must be a real number between 1 and 100').required('Threshold is required').min(1).max(100).nullable(),
    notify_team_member: yup.object().shape({
      users: yup.array().when('fallback_counter', {
        is: (value) => value !== 0,
        then: yup
          .array()
          .min(1, 'Please select at least one team member'),
        otherwise: yup.array().notRequired(),
      }),
      fallback_counter: yup
        .number()
        .transform((value) => (isNaN(value) ? -1 : value))
        .integer()
        .required('This field is required')
        .max(10, 'must be less than or equal to 10')
        .min(0, 'must be greater than or equal to 0')
        .nullable()
        .typeError('This field is required'),
    }).test((value, context) => {
      if (
        value.fallback_counter >=
        context.parent.trigger_topic_flow.fallback_counter
      )
        return context.createError({
          message: 'Number must be less than trigger topic flow',
          path: 'notify_team_member.fallback_counter',
        });
      return true;
    }),
    trigger_topic_flow: yup
      .object()
      .shape({
        topic_flow: yup
          .object()
          .required('This field is required')
          .nullable(),
        fallback_counter: yup
          .number()
          .transform((value) => (isNaN(value) ? -1 : value))
          .integer()
          .required('This field is required')
          .max(10, 'must be less than or equal to 10')
          .nullable()
          .typeError('This field is required'),
      })
      .test((value, context) => {
        if (
          value.fallback_counter <=
          context.parent.notify_team_member.fallback_counter
        )
          return context.createError({
            message: 'Number must be greater than team member',
            path: 'trigger_topic_flow.fallback_counter',
          });
        return true;
      }),
    fallback_messages: yup
      .array()
      .of(
        yup.object().shape({
          text: yup.string().required('This field is required ').matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
        })
      )
      .required('Must have fields')
      .min(1, 'Minimum of 1 field'),
    can_skip: yup.boolean(),
    skip_text: yup
      .string()
      .nullable()
      .when('can_skip', {
        is: true,
        then: yup
          .string()
          .required('This field is required')
          .nullable().matches(/^(?!\s+$).*/, 'Text cannot be just spaces'),
        otherwise: yup.string().notRequired(),
      }),
  });
  return schema;
};
export const getSaveUserAttributeInputNamesSchema = () => {
  return {
    can_skip: 'can_skip',
    skip_text: 'skip_text',
    success_message: 'success_message',
    is_email_or_phone: 'is_email_or_phone',
    question: 'question',
    variation: 'variation',
    trigger_topic_flow: {
      trigger_topic_flow: 'trigger_topic_flow',
      fallback_counter: 'fallback_counter',
      topic_flow: 'topic_flow',
    },
    notify_team_member: {
      notify_team_member: 'notify_team_member',
      fallback_counter: 'fallback_counter',
      users: 'users',
    },
    fallback_messages: 'fallback_messages',
    check_custom_field: 'check_custom_field',
    check_entity: 'check_entity',
    entity_id: 'entity_id',
    threshold: 'threshold',
  };
};
/** ***** end */
