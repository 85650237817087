import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, Switch, TextField, Typography, makeStyles } from '@material-ui/core'
import plusIcon from 'assets/icons/plusIcon.svg';
import React, { useEffect, useRef, useState } from 'react'
import NoDataComponent from 'components/NoData/Component';
import noLists from 'assets/images/empty-lists.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ValueRow from '../components/ValueRow';
import { useSnackbar } from 'notistack';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';

const Component = ({
  getAllEntityLists,
  lists,
  synonymId,
  activeTalkybot,
  addNewEntityList,
  updateEntityList,
  deleteEntityList,
  addNewSynonymValue,
  deleteSynonymValue,
  editSynonymValue,
  handleRefreshPriority,
  synonym,
  getAllSynonymValues,
  values,
  valuesCount,
}) => {
  const classes = useStyles();
  const [isPrivate, setIsPrivate] = useState
    (false);
  const [isActive, setIsActive] = useState(false);

  const inputRef = useRef(null);
  const botBuilderAppId = activeTalkybot?.bot_builder_id;

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();
  const validationSchema = yup.object().shape({
    value: yup
      .string().required('You should write a value').test({
        name: 'notOnlySpaces',
        message: 'Value cannot be only spaces',
        test: (note) => {
          // Check if the value is not only spaces
          return !(/^\s+$/.test(note));
        },
      }).min(3).max(30)
    ,
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
    },
  });

  const handleSubmitCreateValue = (payload) => {
    if (
      Object.keys(errors).length === 0) {
      addNewSynonymValue({
        synonymId: synonym.id,
        botBuilderAppId,
        value: payload?.value?.replace(/\s+/g, ' ').trim(),
        enqueueSnackbar,
        setUiLoadingError,
      })
      setValue('value', '')
    }
  };


  const handleInputBlur = () => {
    // Unfocus the input when blurred
    inputRef.current.blur();
  };

  const handlePrivateChange = (event) => {
    setIsPrivate(event.target.checked);
  };

  const handleActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  useEffect(() => {
    getAllSynonymValues({ synonymId, botBuilderAppId })
  }, [])



  return (
    <div style={{ width: '100%', marginTop: '23px' }}>
      <Accordion
        key={synonym?.id}
        expanded
      >
        <AccordionSummary
          aria-controls={`panel-${synonym?.id}-content`}
          id={`panel-${synonym?.id}-header`}
          className={classes.collapseHeader}
        >
          <div className={classes.listNameContainer}>
            <Typography className={classes.listName}>{synonym.attributes.title}</Typography>
            <Typography className={classes.listThreshold}>Threshold: {synonym.attributes.threshold}%</Typography>

          </div>
          <div className={classes.actions} onClick={(e) => {

          }}>
          </div>
          <Typography className={classes.valuesCount}>Values: {valuesCount}</Typography>
          <div className={classes.rightContainer}>
            <div className={classes.divider}></div>
            <FormControlLabel
              control={<Checkbox
                checked={isPrivate}
                style={{
                  color: isPrivate ? '#6418C3' : undefined,
                }}
                onChange={handlePrivateChange}
              />}
              label={<Typography className={classes.formControlLabel}>Set As Private</Typography>}
            />
            <div className={classes.divider}></div>
            <FormControlLabel
              control={<Switch
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={isActive} onChange={handleActiveChange} />}
              label={<Typography className={classes.formControlLabel}>Active</Typography>}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsContainer}>
          <div className={classes.inputLabelsContainer}>
            <span className={classes.valueText}>Value</span>
          </div>
          <div className={classes.inputsWrapper}>
            <form onSubmit={handleSubmit(handleSubmitCreateValue)} className={classes.InputsContainer}>


              <Controller
                name='value'
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    ref={inputRef}
                    variant="outlined"
                    size='small'
                    placeholder='Write Value'
                    className={classes.valueInput}
                    error={errors?.value?.message}
                    onBlur={handleInputBlur}
                    helperText={errors?.value?.message}
                  />
                )}
              />
              <Button
                className={classes.addButton}
                type="submit"
              // onClick={handleAddNewValue}
              >
                <img
                  src={plusIcon}
                  alt="add topic flow"
                  className={classes.plusIcon}
                />
                Add
              </Button>
            </form>
            {values?.map((value, i) => {
              return (
                <ValueRow
                  key={value.id}
                  value={value}
                  synonymId={synonym?.id}
                  i={i}
                  editSynonymValue={editSynonymValue}
                  deleteSynonymValue={deleteSynonymValue}
                  botBuilderAppId={botBuilderAppId}
                />
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>

    </div>
  )
}

export default Component

const useStyles = makeStyles((theme) => ({

  plusIcon: {
    width: '18px',
    marginRight: '0.6rem',
    '@media (max-width: 1363px)': {
      width: '16px',
    },
  },
  rightContainer: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  formControlLabel: {
    fontSize: '16px', // Set the font size
    fontWeight: '500', // Set the font weight
  },
  switchBase: {
    color: '#ccc',  // default color
    '&$checked': {
      color: 'green',
    },
    '&$checked + $track': {
      backgroundColor: 'green',
    },
  },
  checked: {},
  track: {
    color: 'white'
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '30px',
    flexBasis: '10%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '20%',
    flexShrink: '0',
    justifyContent: 'center',
    gap: '20px'
  },
  actionButton: {
  },
  collapseHeader: {
    display: 'flex',
    padding: '0 10px',
    backgroundColor: 'red',
    height: '53px',
    minHeight: '53px',
    border: 'none',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',

  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '22%',
    flexShrink: '0',
    justifyContent: 'space-between',
  },
  listName: {
    fontSize: '16px',
    fontWeight: '500',
  },
  listThreshold: {
    fontSize: '16px',
    color: '#909090',
  },
  valuesCount: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',

  },
  divider: {
    width: '0px',
    height: '53px',
    border: '0.1px solid #E3E3E3',
    opacity: '1',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px'
  },
  inputLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    padding: '0 10px'
  },
  valueText: {
    flexBasis: '60%',
    flexShrink: '0'
  },
  InputsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    height: '50px',
    alignItems: 'center',
    marginTop: '10px',
  },
  addButton: {
    backgroundColor: 'rgba(100, 24, 195, 0.08)',
    backgroundColorOpacity: '0.08',
    borderRadius: '7px',
    color: '#6418C3',
    height: '40px',
    width: '111px',
    fontSize: '16px'

  },
  inputsWrapper: {
    display: 'flex',
    gap: '25px',
    flexDirection: 'column',
  },
  valueActionsContainer: {
    display: 'flex',
    flexDirection: 'rown',
    gap: '10px',
  },
  editValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(144, 144, 144, 0.08)',
    borderRadius: '7px'
  },
  deleteValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(209, 26, 68, 0.08)',
    borderRadius: '7px'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '14%',
    '@media (max-width: 1363px)': {
      flexBasis: '20%',
    },
    flexShrink: '0',
    justifyContent: 'center',
    gap: '20px',
  },
  listsContainer: {
    width: '100%',
    maxHeight: '100%',
    '@media (max-width: 1363px)': {
      width: '100%',
    },
    border: '1px solid #4E4E4E',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    boxShadow: 'none',

    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '53px',
      minHeight: '0',
      border: 'none',
      boxShadow: 'none',
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },

    '& ::-webkit-scrollbar': {
      width: '0',
    },

  },
  collapseHeader: {
    display: 'flex',
    padding: '0 10px',
    backgroundColor: '#F0F0F0',
    height: '53px',
    minHeight: '53px',
    border: 'none',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',

  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '22%',
    flexShrink: '0',
    justifyContent: 'space-between',
    '@media (max-width: 1363px) and (max-height: 700px)': {
      flexBasis: '25%',
    },
  },
  listName: {
    fontSize: '16px',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },
    fontWeight: '500',
  },
  listThreshold: {
    fontSize: '16px',
    color: '#909090',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },
  },
  valuesCount: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },

  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px'
  },
  inputLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    padding: '0 10px'
  },
  valueText: {
    flexBasis: '52.5%',
    flexShrink: '0',
    '@media (max-width: 1363px)': {
      flexBasis: '52.3%',
    },
  },
  InputsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    height: '40px',
    alignItems: 'center',
    marginTop: '10px',
  },
  addButton: {
    backgroundColor: 'rgba(100, 24, 195, 0.08)',
    backgroundColorOpacity: '0.08',
    borderRadius: '7px',
    color: '#6418C3',
    height: '38px',
    width: '100%',
    fontSize: '16px',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },

  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  valueActionsContainer: {
    display: 'flex',
    flexDirection: 'rown',
    gap: '10px',
  },
  editValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(144, 144, 144, 0.08)',
    borderRadius: '7px'
  },
  deleteValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(209, 26, 68, 0.08)',
    borderRadius: '7px'
  },
  valueInput: {
    flexBasis: '88.65%',
    flexShrink: '0',
    borderRadius: '5px',
    '@media (max-width: 1363px)': {
      flexBasis: '86%',
    },
  },
  plusIcon: {
    width: '14px',
    marginRight: '0.6rem',
    '@media (max-width: 1363px)': {
      width: '14px',
    },
  },
}));
